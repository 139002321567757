// import { types } from "../types/types";

const inicial = {items: []};

export const ProdReducer = ( state = inicial, action)  => {
    switch ( action.type ) {
        case 'ADD_PROD': 
            return {
                ...state,
                items:  [ action.payload, ...state.items ]
            };
        case 'CLEAR_PROD':
            return inicial
        default:
            return state
    }
}