import { 
    createStore, 
    combineReducers, 
    applyMiddleware, 
    compose 
} from 'redux';
import thunk from 'redux-thunk';

import { uiReducer } from '../reducers/uiReducer';
import { ProdReducer } from '../reducers/ProdReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    ui: uiReducer,
    productos: ProdReducer,
})

export const store = createStore( 
    reducers, 
    composeEnhancers(
        applyMiddleware( thunk )
    )    
);