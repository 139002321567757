import * as React from 'react';
// import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from '@material-ui/core';
import noFoto from '../assets/img/no_foto.png';

const useStyles = makeStyles((theme) => ({
  tarjeta : {
    // marginTop: '200px',
    marginTop: theme.spacing(9),
    margin: 'auto',
    maxWidth: '600',
    flexGrow: '1',
  },
  fondo: {
    // backgroundColor: 'rgb(235, 238, 242)',
    // backgroundColor: 'rgb(190, 190, 190)',
    // backgroundColor: '#222121',
  },
  imagen: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    transition: 'transform .3s',
    '&:hover': {
      transform: 'scale(1.5)',
      // border: '1px solid rgb(190, 190, 190)',
      boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',      
    },
  },
}));

// const Imagen = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   maxWidth: '100%',
//   maxHeight: '100%'
// });

export const Ficha = ( {item} ) => {
    const classes = useStyles();
    const {rutafoto, 
      sku, 
      upc, 
      descripcion, 
      pv1, 
      marca,
      depto, 
      clase, 
      subclase, 
      temporada} = item;
    return (
        <Paper elevation={3} sx={{ p: 2, margin: '50px auto', maxWidth: 800, flexGrow: 1  }} >
        <Grid container spacing={2}>

          <Grid item className={classes.fondo} >
            <ButtonBase sx={{ width: 400, height: 400, border: '1px solid rgb(190, 190, 190)' }}>
              <img alt="Imagen" src={ rutafoto || noFoto } className={classes.imagen} />
              {/* <Imagen alt="Imagen" src={ rutafoto || noFoto } /> */}
            </ButtonBase>
          </Grid>

          <Grid item xs={12} sm container>

            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h6" component="div">
                  SKU: { sku }
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  UPC: { upc }
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  { descripcion }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Marca: { marca }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Depto: { depto }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Clase: { clase }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Subclase: { subclase }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Temporada: { temporada }
                </Typography>
              </Grid>
              <Grid item>
                {/* <Typography sx={{ cursor: 'pointer' }} variant="body2">
                  Remove
                </Typography> */}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" component="div">
                ${ pv1 }
              </Typography>
            </Grid>

          </Grid>

        </Grid>
        </Paper>
    );
  }