import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@mui/material/styles';
import { blue, red } from '@material-ui/core/colors';
import { HomePage } from "./pages/HomePage";
import { store } from './store/store'
import { Provider } from 'react-redux';

window.$baseApi = 'http://gregorysoft.viewdns.net:55000/apiBios/1.0.0.0'; 
// window.$baseApi = 'http://192.168.1.30:55000/apiBios/1.0.0.0'; 

const theme = createTheme({
  palette: {
    primary: {
      main: blue[600],
    },
    secondary: {
      main: red[700],
    },
  },
});

function App() {
  return (
    <Provider store={ store }>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HomePage />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
