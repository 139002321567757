import { setError, setLoading } from "./ui";

export const BuscaProducto = ( aCodigo ) => {
    return ( dispatch ) => {
        const url = `${window.$baseApi}/TProductos/FichaProducto/${aCodigo}`;
        try {
            dispatch( setLoading( true ));
            fetch( url ).then( res => {
                res.json().then( data => {
                    if( data !== null ) {
                       localStorage.setItem('lastProd', aCodigo);
                       dispatch( setProducto ( data[0] ));
                       dispatch( setLoading( false ));
                    } else {
                        dispatch( setLoading( false ));
                        dispatch( setError('Producto no valido'));
                    }
                })
            })
            .catch( err => {
                dispatch( setLoading( false ));
                dispatch( setError( err ));
            })
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ));
        }
    }
}

export const setProducto = ( Producto ) => {
    return ({
        type: 'ADD_PROD',
        payload: Producto
    })    
}

export const clearProducto = () => {
    return ({
        type: 'CLEAR_PROD',
    })
}