import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { setError } from '../actions/ui';
import { BarMenu } from '../components/BarMenu';
import { Ficha } from '../components/Ficha';
import { Waiting } from '../components/Waiting/Waiting';
import StyleHome from './StyleHome';

export const HomePage = () => {
    const classes = StyleHome();
    const { items } = useSelector(state => state.productos);
    const { msgError, loading } = useSelector(state => state.ui);
    const dispatch = useDispatch();

    const showError = () => {
        if ( msgError !== null ) {
            Swal.fire({
            icon: 'error',
            // titleText: 'ERROR:',
            // text: 'Ese producto no existe',
            title: 'ERROR: Ese producto no existe',
            showConfirmButton: false,
            // showCloseButton: true,
            timer: 2000,
            showCancelButton: true,
            cancelButtonText: 'Aceptar',
            cancelButtonColor: '#d33',
            // cancelButtonColor: '#338bdd',
            timerProgressBar: true,
            
            showClass: {
                // popup: 'animate__animated animate__fadeInDown'
                popup: 'animate__animated animate__bounceInDown'
            },
            hideClass: {
                // popup: 'animate__animated animate__fadeOutUp'
                popup: 'animate__animated animate__bounceOutUp'
            },
            
            });    
            dispatch( setError(null) ); 
        }
    }

    return (
        <>
            <BarMenu />
            { loading && <Waiting />}
            { showError() }
            <main className={classes.content}>
                {/* <div className={classes.toolbar}> */}
                    {
                        items.map((item, idx) => {
                            return <Ficha
                                key={idx}
                                item={item}
                            />
                        })
                    }
                {/* </div> */}
            </main>
        </>
    )
}
