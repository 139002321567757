import React, { useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Swal from 'sweetalert2';

import { BuscaProducto, clearProducto } from '../actions/acProductos';
import { useDispatch } from 'react-redux';
import imgVZ from '../assets/img/VZ128.png';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: 50,
    // `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  toolbar: theme.mixins.toolbar,
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    maxWidth: '48px',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    '&:hover': {
      cursor: 'pointer',
    },

  }
}));

export const BarMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [aCodigo, setACodigo] = useState('');

  const handleSearch = ( e ) => {
    e.preventDefault();
    if (aCodigo.trim().length >= 6) {
      dispatch( BuscaProducto( aCodigo ) );
      setACodigo('');
    }
  }

  const onClickTitle = () => {
    dispatch( clearProducto() );
  }

  const onedCodigoChange = ( e ) => {
    setACodigo(e.target.value);
  }

  const showMe = () => {
    // dispatch ( setLoading ( true ));

    Swal.fire({
      title: 'Systemaz ',   
      text: 'Copyright © Systemaz 2021.',
      imageUrl: 'http://gorostiza.systemaz.com.mx/static/media/logo.76f4f45a.png',
      // imageWidth: 100,
      imageHeight: 150,
      imageAlt: 'Logo',
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#338bdd',
      // toast: true, 
    })
  }

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <LibraryAddCheckIcon
            className={classes.menuButton}
          />

          <Typography className={classes.title} variant="h6" noWrap onClick={ onClickTitle }>
            Ficha Técnica
          </Typography>

          <div className={ classes.search }>
            <div className={ classes.searchIcon }>
              <SearchIcon />
            </div>
            <form onSubmit={ handleSearch } noValidate >
              <InputBase
                placeholder="Producto…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                name='aCodigo'
                value={ aCodigo }
                autoFocus
                required
                onChange={ onedCodigoChange }

                inputProps={{ 'aria-label': 'search' }}
              />
            </form>
          </div>

          <div className={classes.grow} />
          <img alt="GV" src={ imgVZ } onClick={ showMe } className={ classes.logo } />
        </Toolbar>
      </AppBar>
    </div>
  );
}
